import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
//import { MatButtonModule, MatButtonToggleModule, MatCardModule, MatCheckboxModule, MatDatepickerModule, MatDialogModule, MatFormFieldModule, MatIconModule, MatInputModule, MatNativeDateModule, MatRadioModule, MatSelectModule, MatSidenavModule } from '@angular/material';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatCardModule } from '@angular/material/card';
import { MatTableModule } from '@angular/material/table';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatSelectModule } from '@angular/material/select';
import { MatIconModule } from '@angular/material/icon';
import { MatTabsModule } from '@angular/material/tabs';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSliderModule } from '@angular/material/slider';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatRadioModule } from '@angular/material/radio';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatProgressBarModule } from '@angular/material/progress-bar';

import { FormsModule } from '@angular/forms';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { environment } from '../environments/environment';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { CurrentVisitorAccount } from './services/current-visitor-account';
import { Globals } from './services/globals';
import { Api } from './services/api';
import { LoginComponent } from './components/login/login.component';
import { MeComponent } from './components/me/me.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ResetPasswordComponent } from './components/reset-password/reset-password.component';
import { MyHistoryComponent } from './components/me/history/history.component';
import { VisitHistoryEntryComponent } from './components/me/history/visit-history-entry/visit-history-entry.component';
import { InvitationHistoryEntryComponent } from './components/me/history/invitation-history-entry/invitation-history-entry.component';
import { GiftCardHistoryEntryComponent } from './components/me/history/giftcard-history-entry/giftcard-history-entry.component';
import { SharedAppModule } from 'projects/shared-app/src/app/app.module';
import { VisitResultsComponent } from './components/me/history/visit-history-entry/visit-results/visit-results.component';

export function HttpLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(http, `${environment.baseUrl}/api/translations/`, "/visitor");
}

@NgModule({
    declarations: [
        AppComponent,
        LoginComponent,
        MeComponent,
        ResetPasswordComponent,
        MyHistoryComponent,
        VisitHistoryEntryComponent,
        InvitationHistoryEntryComponent,
        GiftCardHistoryEntryComponent,
        VisitResultsComponent
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        AppRoutingModule,
        MatCardModule,
        MatFormFieldModule,
        MatInputModule,
        MatIconModule,
        MatButtonModule,
        MatDialogModule,
        MatCheckboxModule,
        MatSelectModule,
        MatDatepickerModule,
        MatNativeDateModule,
        MatButtonToggleModule,
        MatRadioModule,
        MatSidenavModule,
        FormsModule,
        HttpClientModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            }
        }),
        SharedAppModule
    ],
    providers: [
        CurrentVisitorAccount,
        Globals,
        Api,
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
