import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './components/login/login.component';
import { MyHistoryComponent } from './components/me/history/history.component';
import { MeComponent } from './components/me/me.component';
import { ResetPasswordComponent } from './components/reset-password/reset-password.component';


const routes: Routes = [
    { path: 'login', component: LoginComponent },
    { path: 'reset-password', component: ResetPasswordComponent },
    {
        path: 'me', component: MeComponent, children: [
            { path: 'history', component: MyHistoryComponent }
        ]
    },
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule { }
