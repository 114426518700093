import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { MegatimingResultComponent } from './components/third-party/megatiming/gokart-results.component';
import { HttpClient } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHelper } from './services/translation-helper';
import { Observable } from 'rxjs';
//import { MatCardModule, MatFormFieldModule, MatInputModule, MatIconModule, MatButtonModule, MatDialogModule, MatCheckboxModule, MatSelectModule, MatDatepickerModule, MatNativeDateModule, MatButtonToggleModule, MatRadioModule, MatSidenavModule, MatTooltipModule } from '@angular/material';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatCardModule } from '@angular/material/card';
import { MatTableModule } from '@angular/material/table';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatSelectModule } from '@angular/material/select';
import { MatIconModule } from '@angular/material/icon';
import { MatTabsModule } from '@angular/material/tabs';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSliderModule } from '@angular/material/slider';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatRadioModule } from '@angular/material/radio';

import { PrisonIslandResultComponent } from './components/third-party/prison-island/prison-island-results.component';
import { Api } from './services/api';
import { Utility } from './services/utility';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SharedGlobals } from './services/shared-globals';
import { BowlitResultComponent } from './components/third-party/bowlit/bowlit-results.component';
import { VBSResultComponent } from './components/third-party/vbs/vbs-results.component';

export function SharedHttpLoaderFactory(http: HttpClient) {
    console.log('Shared loader factory');
    return new TranslateHttpLoader(http, "/api/translations/", "/shared");
}


@NgModule({
    declarations: [
        MegatimingResultComponent,
        PrisonIslandResultComponent,
        BowlitResultComponent,
        VBSResultComponent
    ],
    imports: [
        BrowserModule,
        MatCardModule,
        MatFormFieldModule,
        MatInputModule,
        MatIconModule,
        MatButtonModule,
        MatDialogModule,
        MatCheckboxModule,
        MatSelectModule,
        MatDatepickerModule,
        MatNativeDateModule,
        MatButtonToggleModule,
        MatRadioModule,
        MatSidenavModule,
        MatTooltipModule,
        BrowserAnimationsModule,
        TranslateModule.forChild({}),
    ],
    exports: [
        MegatimingResultComponent,
        PrisonIslandResultComponent,
        BowlitResultComponent,
        VBSResultComponent
    ],
    providers: [
        TranslateHelper,
        Api,
        Utility,
        SharedGlobals
    ]
})
export class SharedAppModule { }
