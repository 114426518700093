import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Api } from 'projects/visitor-app/src/app/services/api';
import { CurrentVisitorAccount } from 'projects/visitor-app/src/app/services/current-visitor-account';
import { Globals } from 'projects/visitor-app/src/app/services/globals';
import { environment } from 'projects/visitor-app/src/environments/environment';

@Component({
    selector: 'invitation-history-entry',
    templateUrl: './invitation-history-entry.component.html',
    styleUrls: ['./invitation-history-entry.component.scss']
})
export class InvitationHistoryEntryComponent implements OnInit {
    @Input()
        historyEntry;
    invitationUrl;
    constructor(private api: Api, private router: Router, private globals: Globals, private currentVisitorAccount: CurrentVisitorAccount) {
        ;
    }


    async ngOnInit() {
        if (this.historyEntry.data.invitationId) {
            this.invitationUrl = `${environment.bookingBaseUrl}/api/public/clients/${this.historyEntry.clientId}/invitations/${this.historyEntry.data.invitationId}/view`;
        }

    }

}
