import { Component, Input, ViewChild, ElementRef } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import moment from 'moment';
import { IVBSVisitResult } from '../../../../../../../../../../common/common-third-party/vbs';
import { TranslateHelper } from '../../../services/translation-helper';

let baseUrl = 'https://vikingcloud.eu/games/';

@Component({
    selector: 'shared-vbs-results',
    templateUrl: './vbs-results.component.html',
    styleUrls: ['./vbs-results.component.scss']
})
export class VBSResultComponent {
    @Input()
        result: IVBSVisitResult;
    @Input()
        darkMode: boolean;

    iframeUrl: SafeUrl;
    constructor(private translateHelper: TranslateHelper, private translate: TranslateService, private sanitizer: DomSanitizer) {
        this.translate.currentLang = '';
        this.translate.setDefaultLang('en');
        this.translate.use(navigator.language);
    }


    ngOnInit() {

    }

    ngAfterViewInit(): void {
        this.iframeUrl = this.sanitizer.bypassSecurityTrustResourceUrl(`${baseUrl}${this.result.bookingId.toString(16)}${this.result.locationId}.pdf`);
    }

}
