import { Component, Input, ViewChild, ElementRef } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import moment from 'moment';
import { IMegatimingGokartVisitResult } from '../../../../../../../../../../common/common-third-party/megatiming/interfaces';
import { TranslateHelper } from '../../../services/translation-helper';

@Component({
    selector: 'shared-megatiming-gokart-results',
    templateUrl: './gokart-results.component.html',
    styleUrls: ['./gokart-results.component.scss']
})
export class MegatimingResultComponent {
    @Input()
        result: IMegatimingGokartVisitResult;

    bestLapTime: string[] = [];
    constructor(private translateHelper: TranslateHelper, private translate: TranslateService) {
        this.translate.currentLang = '';
        this.translate.setDefaultLang('en');
        this.translate.use(navigator.language);
        //translateHelper.init();

        //  this.result.heats[0].result.participants[0].gapToFirst
    }

    getBestTime(time1: string, time2: string): string {
        let timeData = split => {
            return {
                min: split.length > 2 ? parseInt(split[split.length - 3]) : 0,
                sec: split.length > 1 ? parseInt(split[split.length - 2]) : 0,
                ms: split.length > 0 ? parseInt(split[split.length - 1]) : 0,
            };
        };

        let timeData1 = timeData(time1.split(':'));
        let timeData2 = timeData(time2.split(':'));

        if (timeData1.min < timeData2.min) {
            return time1;
        }
        else if (timeData1.min == timeData2.min) {
            if (timeData1.sec < timeData2.sec) {
                return time1;
            }
            else if (timeData1.sec == timeData2.sec) {
                if (timeData1.ms < timeData2.ms) {
                    return time1;
                }
                else {
                    return time2;
                }
            }
            else {
                return time2;
            }
        }
        else {
            return time2;
        }
    }

    ngOnInit() {

        for (let heat of this.result.heats) {
            let bestTime = heat.result.participants[0].bestLapTime;
            for (let participant of heat.result.participants) {
                bestTime = this.getBestTime(bestTime, participant.bestLapTime);
            }
            this.bestLapTime.push(bestTime);
        }

    }

    ngAfterViewInit(): void {
        console.log(this.translate.instant('HEAT'));
    }

    textFromMilliseconds(ms: number) {
        return moment.utc(moment.duration(ms).asMilliseconds()).format("mm:ss.SSS");
    }

}
