import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import moment from 'moment';
import { Api } from '../../../services/api';
import { CurrentVisitorAccount } from '../../../services/current-visitor-account';
import { Globals } from '../../../services/globals';

@Component({
    selector: 'my-history',
    templateUrl: './history.component.html',
    styleUrls: ['./history.component.scss']
})
export class MyHistoryComponent implements OnInit {
    historyEntries;
    historyEntriesByYear: {
        year: string,
        entries: any[]
    }[];
    clientData: {
        [clientId: string]: {
            name: string,
            logoUrl: string
        }
    };
    constructor(private api: Api, private router: Router, private globals: Globals, private currentVisitorAccount: CurrentVisitorAccount) {

    }


    async ngOnInit() {
        this.historyEntries = await this.api.visitor().get<any>('/me/history');
        this.clientData = await this.api.visitor().get<any>('/me/history/client-data');

        this.historyEntriesByYear = [];

        if(this.historyEntries && this.historyEntries.length){
            let currentYear: any = {
                year: moment(this.historyEntries[0].date).format('YYYY'),
                entries: []
            };

            for (let entry of this.historyEntries) {
                let year = moment(entry.date).format('YYYY');
                if (currentYear.year != year) {
                    this.historyEntriesByYear.push(currentYear);
                    currentYear = {
                        year: year,
                        entries: []
                    };
                }
                currentYear.entries.push(entry);
            }
            this.historyEntriesByYear.push(currentYear);
        }

    }

    entryDate(date) {
        return moment(date).format('D MMM');
    }

}
