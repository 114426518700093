import { Component, OnInit, ViewChild } from '@angular/core';
import { Api } from '../../services/api';
import { Router } from '@angular/router';
import { Globals } from '../../services/globals';
import { CurrentVisitorAccount } from '../../services/current-visitor-account';
import { MatSidenav } from '@angular/material/sidenav';

@Component({
    selector: 'me',
    templateUrl: './me.component.html',
    styleUrls: ['./me.component.scss']
})
export class MeComponent implements OnInit {
    menuOpened: boolean;
    backgroundSymbols = [];

    @ViewChild('sidenav', { static: false })
        sidenav: MatSidenav;
    constructor(private api: Api, private router: Router, private globals: Globals, private currentVisitorAccount: CurrentVisitorAccount) {
        for (let i = 0; i < 30; i++)
            this.backgroundSymbols.push(i);
    }

    toggleMenu() {
        console.log(this.sidenav);
        this.sidenav.toggle();
    }

    openMenu() {
        console.log(this.sidenav);
        this.sidenav.open();
    }
    closeMenu() {
        console.log(this.sidenav);
        this.sidenav.close();
    }

    async logOut() {
        let result = await this.api.visitor().post<any>('/logout', {});
        if (result.succeeded) {
            this.closeMenu();
            localStorage.removeItem('visitorAccountAccessToken');
            this.router.navigateByUrl('/login');
        }
    }


    async ngOnInit() {

    }

}
