import { Component, OnInit } from '@angular/core';
import { Api } from '../../services/api';
import { Router } from '@angular/router';
import { Globals } from '../../services/globals';
import { CurrentVisitorAccount } from '../../services/current-visitor-account';

@Component({
    selector: 'login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
    email: string = '';
    password: string = '';
    loggingIn = false;
    loginFailed = false;
    constructor(private api: Api, private router: Router, private globals: Globals, private currentVisitorAccount: CurrentVisitorAccount) {

    }


    async ngOnInit() {
        var video = document.querySelector('video');
        video.muted = true;
        video.playbackRate = 0.8;
        video.play();

        let visitorAccount = await this.currentVisitorAccount.get();
        if (visitorAccount)
            this.router.navigateByUrl('/me');
    }

    async login() {
        this.loggingIn = true;
        let result = await this.api.visitor().post<any>('/login/authenticate', {
            email: this.email,
            password: this.password
        });
        if (result.succeeded) {
            localStorage.setItem('visitorAccountAccessToken', result.accessToken);
            this.router.navigateByUrl('/me');
        } else {
            this.loginFailed = true;
            setTimeout(() => {
                this.loginFailed = false;
            }, 2000);
        }
        console.log(result);
        this.loggingIn = false;
    }
}
