import { Component, Input, ViewChild, ElementRef } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import moment from 'moment';
import { IBowlitVisitResult } from '../../../../../../../../../../common/common-third-party/bowlit';
import { TranslateHelper } from '../../../services/translation-helper';

let baseUrl = 'https://social.bowlit.nu';

@Component({
    selector: 'shared-bowlit-results',
    templateUrl: './bowlit-results.component.html',
    styleUrls: ['./bowlit-results.component.scss']
})
export class BowlitResultComponent {
    @Input()
        result: IBowlitVisitResult;
    @Input()
        darkMode: boolean;

    iframeUrl: SafeUrl;
    constructor(private translateHelper: TranslateHelper, private translate: TranslateService, private sanitizer: DomSanitizer) {
        this.translate.currentLang = '';
        this.translate.setDefaultLang('en');
        this.translate.use(navigator.language);

    }


    ngOnInit() {

    }

    ngAfterViewInit(): void {
        this.iframeUrl = this.sanitizer.bypassSecurityTrustResourceUrl(`${baseUrl}/${this.result.centerId}/${this.result.bookId}?funbutlerEmbed=True${this.darkMode ? '&darkmode=1' : ''}`);
    }

}
